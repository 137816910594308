module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-HB8KZ8EMGY","head":true,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-js-fallback/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aerial Video Experts - 888 Productions","short_name":"888 Productions","start_url":"/","background_color":"#26272F","theme_color":"#C6A54B","display":"standalone","icon":"src/images/888logo.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9e8130b17fb0316859c5adca3be1d5f1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
